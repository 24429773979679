import * as React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"

const FourOhFour = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <FourOhFour>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </FourOhFour>
  </Layout>
)

export default NotFoundPage
